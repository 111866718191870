import React from "react"
import { Link } from 'gatsby';
import App from "../../components/App"
//import Cards from '../../components/Cards';
import '../../components/ContributerX.css';

/*import React from 'react';
import { Link } from 'gatsby';
import "../../components/App.css";*/


export default function BrijBhushan() {
  return (
    <App>
      <div className='right-frame'>

		<div className='contributer_page'>
			<div className='contributer_card'>
				<div className='breadcrumbs_holder'>
				<span className='breadcrumbs'>
					<Link to='/'>Home</Link> : &nbsp;
					<Link to='/contributors'>Contributors</Link> : 
					Brij Bhushan
				</span>
				</div>
      			<img src='/brij-bhushan.jpg' className='contributer_img' alt="Brij Bhushan"></img>
        		<h1>Brij Bhushan</h1>
				<h3>Researcher</h3>
				&nbsp;
				{/*<div className='contributer_text'>*/}
				<p>&nbsp;</p>
				{/*<img className='details_img' src='/images/research.jpg'/>*/}
        		<p> I am a researcher. I got my professional training on researching in IIT Bombay, during my graduation course. The research involves first picking up a fresh topic, then familarizing yourself by light reading on the topic like books and public articles. After that you start searching professionally published material in various journals, on the topic. Then you filter them after reading the abstract. Based on that you decide which papers you should study in detail. After reading all the first set of papers, you map your learning & build a holistic picture where you have no loose ends. If there are loose ends, then you search further in the journals & study the next set of papers to tie the loose ends. You repeat the process till all loose ends are tied up.
        		</p>
        		<p>&nbsp;</p>
        		<img className='details_img2' src='/neckpain.jpg' alt="Neck pain" />
        		<p>
					From my early 20s till late 30s, I researched on engineering related topics. In my early 40s, when I started having health problems, like chronic muscular pain, chronic face acne, obesity, hair loss, then first I consulted various doctors of modern medicine. After a year of the treatment, I realized that the treatment is effective only on the symptoms, whereas the root cause has remained unaddressed. That’s when I decided to start a formal research on the health-related topics, to gain an understanding of what I was dealing with. Initially, I studied mostly material published in medical journals of modern medicine. Medical journals published on PubMed Central (PMC) were a huge help in this work. However, slowly, I started reading Ayurveda texts as well, like Ashtang Hridyam & Bhavprakash. This unbiased approach towards medical knowledge helped me build a holistic understanding of the topics that I researched. Based on the knowledge I gained from the research, I was able to reverse a few medical conditions in myself & my family members, just by a few changes in diet & lifestyle.
				</p>
				<p>&nbsp;</p>
				<img className='details_img' src='/fact-fake.jpg' alt="Fake information is often presented as fact" />
        		<p>
					That’s when I gained this insight that a lot of health related information is missing from the public domain. In several cases, people eat a diet & use a set of health products because they consider them healthy, whereas in reality those products or habits are not healthy. Most of the time, the source of our knowledge on what is healthy are from; advertisements or health articles published in mainstream media. However, both are mostly biased to promote certain products or treatments. Through the articles published on this portal, I am sharing the knowledge I have gathered through my research. All the solutions are based on a few changes in diet & lifestyle. I don’t recommend anyone to stop their treatment. The knowledge presented here is not a replacement to the treatment by modern medicine, rather it is complimentary in nature. While you are going through the treatment, you can make the suggested changes in diet & lifestyle. This will help you recover faster & maintain the results for a longer duration.
				</p>
				<p>&nbsp;</p>
				<img className='details_img2' src='/global.jpg' alt="Global exposure" />
        		<p>
					Apart from engineering, I have also done post-graduation in management. During my 20+ years of career, I have played several roles in engineering & management. As part of my job, I have spent good amount of time in different countries, like the US, UK, Germany, Belgium, UAE, South Korea, Singapore & South Africa. I have also visited Thailand, Indonesia, Switzerland, Mauritius & Vietnam. This exposure has been quite helpful in learning dynamics across the countries, their cultures, health problems of the people and popular treatments in respective geographies.
        		</p>
        		<p>&nbsp;</p>
        		<p>&nbsp;</p>
        		<p>&nbsp;</p>
        

        		{/*</div>*/}
			</div>
		</div>
	  </div>
	</App>
	)
}